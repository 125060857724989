import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"

// components
import Layout from "../components/layout/Layout";
import Newsletter from "../components/newsletter/Newsletter";
import Seo from "../components/seo/SEO";

// styles

const AboutPage = () => {
  const query = useStaticQuery(graphql`
    {
      craft {
        entry(section: "about") {
          ... on Craft_about_about_Entry {
            title
            richTextStandard
            pageSeo {
              description
              title
              keywords {
                keyword
              }
              advanced {
                  robots
                  canonical
              }
              social {
                facebook {
                  image {
                    ... on Craft_images_Asset {
                      url
                    }
                  }
                }
                twitter {
                  image {
                    ... on Craft_images_Asset {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const seo = query.craft.entry.pageSeo;

  let keywords = [];
  if (seo.keywords) {
    seo.keywords.forEach((keyword) => {
      keywords.push(keyword.keyword);
    })
  }

  return (
    <Layout>
      <Seo 
        title={query.craft.entry.title} 
        canonical={seo.advanced.canonical}
        description={seo.description ? seo.description : query.craft.entry.richTextStandard.replace(/<[^>]*>?/gm, '')}
        facebookImage={seo.social.facebook.image?.url}
        robots={seo.advanced.robots}
        twitterImage={seo.social.twitter.image?.url}
        keywords={keywords} />

      <div className={`small-page`}>
        <div className={`m-24`} dangerouslySetInnerHTML={{ __html: query.craft.entry.richTextStandard }} />
        <Newsletter copy={``} />
      </div>
    </Layout>
  )
}

export default AboutPage
